var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.availableLanguages.length > 1)?_c('div',{ref:"target",staticClass:"relative h-full px-[10px]",class:[_vm.dark ? 'text-white' : 'text-black']},[_c('button',{staticClass:"flex h-full min-h-[40px] items-center text-white",on:{"click":_setup.toggle}},[_c('span',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2 text-xs font-medium leading-none",class:_vm.triggerTextClasses},[_vm._v("\n        "+_vm._s(_setup.currentLanguage?.code.toUpperCase())+"\n      ")]),_vm._v(" "),_c('UiIcon',{attrs:{"icon":_setup.isOpen ? 'chevronUp' : 'chevronDown'}})],1)]),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"transform duration-300","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transform duration-300","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[(_setup.isOpen)?_c('div',{staticClass:"absolute right-0 top-full z-20 flex w-[68px] items-end rounded-b-md bg-blue-800"},[_c('div',{staticClass:"max-h-screen w-full overflow-y-auto"},_vm._l((_setup.availableLanguages),function(language,i){return _c(_setup.currentLanguage && language.code !== _setup.currentLanguage.code
              ? 'NuxtLink'
              : 'div',_vm._g({key:i,tag:"component",staticClass:"flex w-full items-center justify-between p-3 transition",class:{
            'hover:opacity-70':
              _setup.currentLanguage && language.code !== _setup.currentLanguage.code,
          },attrs:{"to":_vm.switchLocalePath(language.code)}},
            _setup.currentLanguage && language.code === _setup.currentLanguage.code
              ? { click: _setup.close }
              : {}
          ),[_c('span',{staticClass:"mr-2 text-xs font-medium leading-none"},[_vm._v("\n            "+_vm._s(language.code.toUpperCase())+"\n          ")]),_vm._v(" "),(_setup.currentLanguage && language.code === _setup.currentLanguage.code)?_c('UiIcon',{attrs:{"icon":"done"}}):_vm._e()],1)}),1)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }