
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    search: () => import('@/components/icons/UiIconSearch.vue'),
    user: () => import('@/components/icons/UiIconUser.vue'),
    menubar: () => import('@/components/icons/UiIconMenuBar.vue'),
    close: () => import('@/components/icons/UiIconClose.vue'),
    arrowRight: () => import('@/components/icons/UiIconArrowRight.vue'),
    sliderArrowLeft: () =>
      import('@/components/icons/UiIconSliderArrowLeft.vue'),
    sliderArrowRight: () =>
      import('@/components/icons/UiIconSliderArrowRight.vue'),
    chevronUp: () => import('@/components/icons/UiIconChevronUp.vue'),
    chevronDown: () => import('@/components/icons/UiIconChevronDown.vue'),
    chevronRight: () => import('@/components/icons/UiIconChevronRight.vue'),
    chevronLeft: () => import('@/components/icons/UiIconChevronLeft.vue'),
    facebook: () => import('@/components/icons/UiIconFacebook.vue'),
    twitter: () => import('@/components/icons/UiIconTwitter.vue'),
    instagram: () => import('@/components/icons/UiIconInstagram.vue'),
    telegram: () => import('@/components/icons/UiIconTelegram.vue'),
    youtube: () => import('@/components/icons/UiIconYoutube.vue'),
    linkedin: () => import('@/components/icons/UiIconLinkedIn.vue'),
    logoMain: () => import('@/components/icons/UiIconLogoMain.vue'),
    logoMainRO: () => import('@/components/icons/UiIconLogoMainRO.vue'),
    play: () => import('@/components/icons/UiIconPlay.vue'),
    checkmark: () => import('@/components/icons/UiIconCheckmark.vue'),
    copy: () => import('@/components/icons/UiIconCopy.vue'),
    quote: () => import('@/components/icons/UiIconQuote.vue'),
    pdfFile: () => import('@/components/icons/UiIconPdfFile.vue'),
    download: () => import('@/components/icons/UiIconDownload.vue'),
    'icon-filter': () => import('@/components/icons/UiIconFilter.vue'),
    plus: () => import('@/components/icons/UiIconPlus.vue'),
    minus: () => import('@/components/icons/UiIconMinus.vue'),
    tel: () => import('@/components/icons/UiIconTel.vue'),
    email: () => import('@/components/icons/UiIconEmail.vue'),
    done: () => import('@/components/icons/UiIconDone.vue'),
    'scroll-down': () => import('@/components/icons/UiIconScrollDown.vue'),
    techlands: () => import('@/components/icons/UiIconTechlands.vue'),
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    containerSize: {
      type: Boolean,
      default: false,
    },
  },
});
