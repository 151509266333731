import { inject, watch } from '@nuxtjs/composition-api';
import { useElementHover } from '@vueuse/core';


export default {
  __name: 'UiPopoverTrigger',
  props: {
  element: {
    type: String,
    default: 'button',
  },
  arrow: {
    type: Boolean,
    default: true,
  },
  arrowSize: {
    type: Number,
    default: 16,
  },
},
  setup(__props) {



const isOpen = inject('isOpen');
const toggle = inject('toggle');
const open = inject('open');
const close = inject('close');
const trigger = inject('trigger');
const popoverElement = inject('popoverElement');

const togglePopover = () => {
  if (trigger !== 'click') return;
  toggle();
};

const isHovered = useElementHover(popoverElement);

watch(isHovered, () => {
  if (trigger !== 'hover') return;
  if (isHovered.value === true) open();
  else close();
});

return { __sfc: true,isOpen, toggle, open, close, trigger, popoverElement, togglePopover, isHovered }
}

}