import { inject } from '@nuxtjs/composition-api';


export default {
  __name: 'UiPopoverPanel',
  props: {
  left: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



const isOpen = inject('isOpen');

return { __sfc: true,isOpen }
}

}