import { useContext } from '@nuxtjs/composition-api';

const useRecaptcha = () => {
  const { $axios, $recaptcha } = useContext();

  const getToken = async () => {
    // eslint-disable-next-line no-return-await
    return await $recaptcha.execute('login');
  };

  const validateToken = async (token: string) => {
    const response = await $axios.post('/check-recaptcha-token', { token });

    if (response.data.success === false) {
      throw new Error('Invalid reCAPTCHA token');
    }
    return response.data;
  };

  const validateRecaptcha = async () => {
    const token = await getToken();
    await validateToken(token);
  };

  return {
    validateRecaptcha,
  };
};

export default useRecaptcha;
