import { computed } from '@nuxtjs/composition-api';


export default {
  __name: 'UiTitle',
  props: {
  tag: {
    type: String,
    default: 'h2',
  },
  size: {
    type: String,
    default: 'md',
  },
  align: {
    type: String,
    default: 'center',
  },
  weight: {
    type: String,
    default: 'medium',
  },
},
  setup(__props) {

const props = __props;

const VARIANTS_MAP = {
  lg: 'text-4xl sm:text-6xl',
  md: 'text-3xl sm:text-5xl',
  sm: 'text-2xl sm:text-4xl',
  xs: 'text-xl sm:text-3xl',
  custom: '',
};

const WEIGHT_MAP = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  medium: 'font-medium',
};

const ALIGN_MAP = {
  center: 'text-center',
  left: 'text-left',
  right: 'text-right',
  inherit: '',
};



const classes = computed(() => [
  VARIANTS_MAP[props.size],
  ALIGN_MAP[props.align],
  WEIGHT_MAP[props.weight],
]);

return { __sfc: true,VARIANTS_MAP, WEIGHT_MAP, ALIGN_MAP, props, classes }
}

}