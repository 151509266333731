
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import HeaderMainMenu from '@/domains/menus/components/HeaderMainMenu.vue';
import HeaderTopMenu from '@/domains/menus/components/HeaderTopMenu.vue';
import HeaderMainMenuMobile from '@/domains/menus/components/HeaderMainMenuMobile.vue';
import AppSearch from '@/components/AppSearch.vue';
import { useScroll } from '~/composables';

export default defineComponent({
  components: {
    HeaderMainMenu,
    HeaderTopMenu,
    HeaderMainMenuMobile,
    AppSearch,
  },
  props: {
    menuTop: {
      type: Array,
      default: () => [],
    },
    menuMain: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: undefined,
    },
  },
  setup(props) {
    const { isScrollingBottom } = useScroll();
    const VISIBILITY_CONTACT_SALES_BUTTON = false;
    const mainMenuWrap = ref<HTMLElement>();
    const modalSearch = ref(null);
    const isMenuOpen = ref(false);
    const isMobileMenuOpen = ref(false);

    const headerBgColor = computed(() => {
      if (isMenuOpen.value) return 'bg-gray-100';
      if (props.dark) return 'bg-gray-100';

      return '';
    });

    const toggleMenu = (value: boolean) => {
      isMenuOpen.value = value;
    };

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };

    const closeMobileMenu = () => {
      isMobileMenuOpen.value = false;
    };

    return {
      headerBgColor,
      mainMenuWrap,
      modalSearch,
      isMenuOpen,
      isMobileMenuOpen,
      toggleMenu,
      toggleMobileMenu,
      closeMobileMenu,
      isScrollingBottom,
      VISIBILITY_CONTACT_SALES_BUTTON,
    };
  },
});
