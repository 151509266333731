import { useMediaAsset } from '~/composables';


export default {
  __name: 'CardSectionAppsItem',
  props: {
  image: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: null,
  },
},
  setup(__props) {


const { getAssetUrl } = useMediaAsset();

return { __sfc: true,getAssetUrl }
}

}