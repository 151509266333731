
import { defineComponent } from '@nuxtjs/composition-api';

import HeaderTopMenuItem from '@/domains/menus/components/HeaderTopMenuItem.vue';
import LocationsPopover from '@/domains/menus/components/LocationsPopover.vue';

export default defineComponent({
  components: {
    HeaderTopMenuItem,
    LocationsPopover,
  },
  props: {
    menu: {
      type: Array,
      require: true,
      default: () => [],
    },
    activeItem: {
      type: String,
      default: null,
    },
  },
});
