
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { onClickOutside } from '@vueuse/core';
import { useOpenClose } from '~/composables';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
    closeIconPosition: {
      type: String,
      default: 'inside',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isOpen, open, close } = useOpenClose();

    const modal = ref(null);
    onClickOutside(modal, () => close());

    return {
      close,
      open,
      modal,
      isOpen,
    };
  },
});
