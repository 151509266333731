
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const showAll = ref(false);
    const visibleResults = computed(() => {
      return showAll.value ? props.items : props.items.slice(0, 5);
    });

    const showAllResults = () => {
      showAll.value = true;
    };

    const selectItem = (item: string) => {
      emit('input', item);
    };

    return {
      visibleResults,
      selectItem,
      showAllResults,
      showAll,
    };
  },
});
