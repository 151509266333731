
import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $config } = useContext();
    const { showTechisland } = $config;
    const isTechlandIconShowed = computed(() => !!showTechisland);

    return { isTechlandIconShowed };
  },
});
