
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(_props, { emit }) {
    const selectItem = (item: string) => {
      emit('input', item);
    };

    return {
      selectItem,
    };
  },
});
