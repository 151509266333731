import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _068754fc = () => interopDefault(import('../pages/asbis-in-your-country.vue' /* webpackChunkName: "pages/asbis-in-your-country" */))
const _29ed880e = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _29218fdd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02b735c2 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _3033ec41 = () => interopDefault(import('../pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _6485a753 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _e520580e = () => interopDefault(import('../pages/solution/_slug.vue' /* webpackChunkName: "pages/solution/_slug" */))
const _2d5aca9e = () => interopDefault(import('../pages/vendors/_slug.vue' /* webpackChunkName: "pages/vendors/_slug" */))
const _6952fa9a = () => interopDefault(import('../pages/where-to-buy/_slug.vue' /* webpackChunkName: "pages/where-to-buy/_slug" */))
const _276aee95 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/asbis-in-your-country",
    component: _068754fc,
    name: "asbis-in-your-country___uk"
  }, {
    path: "/vendors",
    component: _29ed880e,
    name: "vendors___uk"
  }, {
    path: "/",
    component: _29218fdd,
    name: "index___uk"
  }, {
    path: "/catalog/:slug?",
    component: _02b735c2,
    name: "catalog-slug___uk"
  }, {
    path: "/documents/:slug",
    component: _3033ec41,
    name: "documents-slug___uk"
  }, {
    path: "/news/:slug?",
    component: _6485a753,
    name: "news-slug___uk"
  }, {
    path: "/solution/:slug?",
    component: _e520580e,
    name: "solution-slug___uk"
  }, {
    path: "/vendors/:slug",
    component: _2d5aca9e,
    name: "vendors-slug___uk"
  }, {
    path: "/where-to-buy/:slug?",
    component: _6952fa9a,
    name: "where-to-buy-slug___uk"
  }, {
    path: "/:slug",
    component: _276aee95,
    name: "slug___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
