
import { defineComponent } from '@nuxtjs/composition-api';
import { useLogos } from '~/composables';

export default defineComponent({
  setup() {
    const { logos } = useLogos();
    return {
      logos,
    };
  },
});
