import { CdnImg } from '@webplatform/cdn-image';


export default {
  __name: 'TeamCard',
  props: {
  image: {
    type: Object,
    default: () => ({}),
  },
  name: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  phones: {
    type: Array,
    default: () => [],
  },
  email: {
    type: String,
    default: '',
  },
},
  setup(__props) {



return { __sfc: true,CdnImg }
}

}