import { onMounted, useContext } from '@nuxtjs/composition-api';
import { useSendForm } from '@webplatform/nuxt-cms-js-sdk';
import useRecaptcha from '~/composables/useRecaptcha';

export default function useForm() {
  const { $recaptcha } = useContext();
  const { validateRecaptcha } = useRecaptcha();
  const { pending, submit: submitForm } = useSendForm();

  onMounted(async () => {
    try {
      await $recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  });

  async function submit(formData: object) {
    await validateRecaptcha();
    await submitForm(formData);
  }

  return { submit, pending };
}
