
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    menuItem: {
      type: Object,
      default() {
        return {};
      },
    },
    isActive: {
      type: Boolean,
    },
  },
});
