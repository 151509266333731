import { render, staticRenderFns } from "./TeamCard.vue?vue&type=template&id=63f55f0a&scoped=true"
import script from "./TeamCard.vue?vue&type=script&setup=true&lang=js"
export * from "./TeamCard.vue?vue&type=script&setup=true&lang=js"
import style0 from "./TeamCard.vue?vue&type=style&index=0&id=63f55f0a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f55f0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiTitle.vue').default,UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiIcon.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/ui/UiLink.vue').default})
