
import { defineComponent } from '@nuxtjs/composition-api';
import { useSocials } from '~/composables';

import FooterMainMenu from '@/domains/menus/components/FooterMainMenu.vue';
import FooterBottomMenu from '@/domains/menus/components/FooterBottomMenu.vue';
import SocialsList from '@/components/SocialsList.vue';

export default defineComponent({
  components: {
    FooterMainMenu,
    SocialsList,
    FooterBottomMenu,
  },
  props: {
    menuFooter: {
      type: Array,
      default: () => [],
    },
    menuBottom: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { socials } = useSocials();
    const currentYear = new Date().getFullYear();

    return {
      currentYear,
      socials,
    };
  },
});
