export const AppBreadcrumbs = () => import('../../components/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLeftSidebar = () => import('../../components/AppLeftSidebar.vue' /* webpackChunkName: "components/app-left-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppSearch = () => import('../../components/AppSearch.vue' /* webpackChunkName: "components/app-search" */).then(c => wrapFunctional(c.default || c))
export const AppleCalculator = () => import('../../components/AppleCalculator.vue' /* webpackChunkName: "components/apple-calculator" */).then(c => wrapFunctional(c.default || c))
export const HtmlContentBlock = () => import('../../components/HtmlContentBlock.vue' /* webpackChunkName: "components/html-content-block" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcherMobile = () => import('../../components/LanguageSwitcherMobile.vue' /* webpackChunkName: "components/language-switcher-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageBlocks = () => import('../../components/PageBlocks.vue' /* webpackChunkName: "components/page-blocks" */).then(c => wrapFunctional(c.default || c))
export const SocialSharesBlock = () => import('../../components/SocialSharesBlock.vue' /* webpackChunkName: "components/social-shares-block" */).then(c => wrapFunctional(c.default || c))
export const SocialsList = () => import('../../components/SocialsList.vue' /* webpackChunkName: "components/socials-list" */).then(c => wrapFunctional(c.default || c))
export const SubscribeNewsletterBlock = () => import('../../components/SubscribeNewsletterBlock.vue' /* webpackChunkName: "components/subscribe-newsletter-block" */).then(c => wrapFunctional(c.default || c))
export const TeamCard = () => import('../../components/TeamCard.vue' /* webpackChunkName: "components/team-card" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerModal = () => import('../../components/VideoPlayerModal.vue' /* webpackChunkName: "components/video-player-modal" */).then(c => wrapFunctional(c.default || c))
export const UiIconArrowRight = () => import('../../components/icons/UiIconArrowRight.vue' /* webpackChunkName: "components/ui-icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconCheckmark = () => import('../../components/icons/UiIconCheckmark.vue' /* webpackChunkName: "components/ui-icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronDown = () => import('../../components/icons/UiIconChevronDown.vue' /* webpackChunkName: "components/ui-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronLeft = () => import('../../components/icons/UiIconChevronLeft.vue' /* webpackChunkName: "components/ui-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronRight = () => import('../../components/icons/UiIconChevronRight.vue' /* webpackChunkName: "components/ui-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconChevronUp = () => import('../../components/icons/UiIconChevronUp.vue' /* webpackChunkName: "components/ui-icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const UiIconClose = () => import('../../components/icons/UiIconClose.vue' /* webpackChunkName: "components/ui-icon-close" */).then(c => wrapFunctional(c.default || c))
export const UiIconCopy = () => import('../../components/icons/UiIconCopy.vue' /* webpackChunkName: "components/ui-icon-copy" */).then(c => wrapFunctional(c.default || c))
export const UiIconDone = () => import('../../components/icons/UiIconDone.vue' /* webpackChunkName: "components/ui-icon-done" */).then(c => wrapFunctional(c.default || c))
export const UiIconDownload = () => import('../../components/icons/UiIconDownload.vue' /* webpackChunkName: "components/ui-icon-download" */).then(c => wrapFunctional(c.default || c))
export const UiIconEmail = () => import('../../components/icons/UiIconEmail.vue' /* webpackChunkName: "components/ui-icon-email" */).then(c => wrapFunctional(c.default || c))
export const UiIconFacebook = () => import('../../components/icons/UiIconFacebook.vue' /* webpackChunkName: "components/ui-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const UiIconFilter = () => import('../../components/icons/UiIconFilter.vue' /* webpackChunkName: "components/ui-icon-filter" */).then(c => wrapFunctional(c.default || c))
export const UiIconInstagram = () => import('../../components/icons/UiIconInstagram.vue' /* webpackChunkName: "components/ui-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const UiIconLinkedIn = () => import('../../components/icons/UiIconLinkedIn.vue' /* webpackChunkName: "components/ui-icon-linked-in" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoMain = () => import('../../components/icons/UiIconLogoMain.vue' /* webpackChunkName: "components/ui-icon-logo-main" */).then(c => wrapFunctional(c.default || c))
export const UiIconLogoMainRO = () => import('../../components/icons/UiIconLogoMainRO.vue' /* webpackChunkName: "components/ui-icon-logo-main-r-o" */).then(c => wrapFunctional(c.default || c))
export const UiIconMenuBar = () => import('../../components/icons/UiIconMenuBar.vue' /* webpackChunkName: "components/ui-icon-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const UiIconMinus = () => import('../../components/icons/UiIconMinus.vue' /* webpackChunkName: "components/ui-icon-minus" */).then(c => wrapFunctional(c.default || c))
export const UiIconPdfFile = () => import('../../components/icons/UiIconPdfFile.vue' /* webpackChunkName: "components/ui-icon-pdf-file" */).then(c => wrapFunctional(c.default || c))
export const UiIconPlay = () => import('../../components/icons/UiIconPlay.vue' /* webpackChunkName: "components/ui-icon-play" */).then(c => wrapFunctional(c.default || c))
export const UiIconPlus = () => import('../../components/icons/UiIconPlus.vue' /* webpackChunkName: "components/ui-icon-plus" */).then(c => wrapFunctional(c.default || c))
export const UiIconQuote = () => import('../../components/icons/UiIconQuote.vue' /* webpackChunkName: "components/ui-icon-quote" */).then(c => wrapFunctional(c.default || c))
export const UiIconScrollDown = () => import('../../components/icons/UiIconScrollDown.vue' /* webpackChunkName: "components/ui-icon-scroll-down" */).then(c => wrapFunctional(c.default || c))
export const UiIconSearch = () => import('../../components/icons/UiIconSearch.vue' /* webpackChunkName: "components/ui-icon-search" */).then(c => wrapFunctional(c.default || c))
export const UiIconSliderArrowLeft = () => import('../../components/icons/UiIconSliderArrowLeft.vue' /* webpackChunkName: "components/ui-icon-slider-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const UiIconSliderArrowRight = () => import('../../components/icons/UiIconSliderArrowRight.vue' /* webpackChunkName: "components/ui-icon-slider-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const UiIconTechlands = () => import('../../components/icons/UiIconTechlands.vue' /* webpackChunkName: "components/ui-icon-techlands" */).then(c => wrapFunctional(c.default || c))
export const UiIconTel = () => import('../../components/icons/UiIconTel.vue' /* webpackChunkName: "components/ui-icon-tel" */).then(c => wrapFunctional(c.default || c))
export const UiIconTelegram = () => import('../../components/icons/UiIconTelegram.vue' /* webpackChunkName: "components/ui-icon-telegram" */).then(c => wrapFunctional(c.default || c))
export const UiIconTwitter = () => import('../../components/icons/UiIconTwitter.vue' /* webpackChunkName: "components/ui-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const UiIconUser = () => import('../../components/icons/UiIconUser.vue' /* webpackChunkName: "components/ui-icon-user" */).then(c => wrapFunctional(c.default || c))
export const UiIconYoutube = () => import('../../components/icons/UiIconYoutube.vue' /* webpackChunkName: "components/ui-icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const UiAccordion = () => import('../../components/ui/UiAccordion.vue' /* webpackChunkName: "components/ui-accordion" */).then(c => wrapFunctional(c.default || c))
export const UiAccordionItem = () => import('../../components/ui/UiAccordionItem.vue' /* webpackChunkName: "components/ui-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const UiAlert = () => import('../../components/ui/UiAlert.vue' /* webpackChunkName: "components/ui-alert" */).then(c => wrapFunctional(c.default || c))
export const UiBanner = () => import('../../components/ui/UiBanner.vue' /* webpackChunkName: "components/ui-banner" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/UiButton.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCard = () => import('../../components/ui/UiCard.vue' /* webpackChunkName: "components/ui-card" */).then(c => wrapFunctional(c.default || c))
export const UiCardExpanded = () => import('../../components/ui/UiCardExpanded.vue' /* webpackChunkName: "components/ui-card-expanded" */).then(c => wrapFunctional(c.default || c))
export const UiCardListItem = () => import('../../components/ui/UiCardListItem.vue' /* webpackChunkName: "components/ui-card-list-item" */).then(c => wrapFunctional(c.default || c))
export const UiCarouselSlide = () => import('../../components/ui/UiCarouselSlide.vue' /* webpackChunkName: "components/ui-carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiDescription = () => import('../../components/ui/UiDescription.vue' /* webpackChunkName: "components/ui-description" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosure = () => import('../../components/ui/UiDisclosure.vue' /* webpackChunkName: "components/ui-disclosure" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosureButton = () => import('../../components/ui/UiDisclosureButton.vue' /* webpackChunkName: "components/ui-disclosure-button" */).then(c => wrapFunctional(c.default || c))
export const UiDisclosurePanel = () => import('../../components/ui/UiDisclosurePanel.vue' /* webpackChunkName: "components/ui-disclosure-panel" */).then(c => wrapFunctional(c.default || c))
export const UiDrawer = () => import('../../components/ui/UiDrawer.vue' /* webpackChunkName: "components/ui-drawer" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/ui/UiIcon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiImage = () => import('../../components/ui/UiImage.vue' /* webpackChunkName: "components/ui-image" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/UiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiLink = () => import('../../components/ui/UiLink.vue' /* webpackChunkName: "components/ui-link" */).then(c => wrapFunctional(c.default || c))
export const UiList = () => import('../../components/ui/UiList.vue' /* webpackChunkName: "components/ui-list" */).then(c => wrapFunctional(c.default || c))
export const UiListItem = () => import('../../components/ui/UiListItem.vue' /* webpackChunkName: "components/ui-list-item" */).then(c => wrapFunctional(c.default || c))
export const UiMarkerPoint = () => import('../../components/ui/UiMarkerPoint.vue' /* webpackChunkName: "components/ui-marker-point" */).then(c => wrapFunctional(c.default || c))
export const UiModal = () => import('../../components/ui/UiModal.vue' /* webpackChunkName: "components/ui-modal" */).then(c => wrapFunctional(c.default || c))
export const UiMultiselect = () => import('../../components/ui/UiMultiselect.vue' /* webpackChunkName: "components/ui-multiselect" */).then(c => wrapFunctional(c.default || c))
export const UiPagination = () => import('../../components/ui/UiPagination.vue' /* webpackChunkName: "components/ui-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiPopover = () => import('../../components/ui/UiPopover.vue' /* webpackChunkName: "components/ui-popover" */).then(c => wrapFunctional(c.default || c))
export const UiPopoverPanel = () => import('../../components/ui/UiPopoverPanel.vue' /* webpackChunkName: "components/ui-popover-panel" */).then(c => wrapFunctional(c.default || c))
export const UiPopoverTrigger = () => import('../../components/ui/UiPopoverTrigger.vue' /* webpackChunkName: "components/ui-popover-trigger" */).then(c => wrapFunctional(c.default || c))
export const UiRadio = () => import('../../components/ui/UiRadio.vue' /* webpackChunkName: "components/ui-radio" */).then(c => wrapFunctional(c.default || c))
export const UiRadioGroup = () => import('../../components/ui/UiRadioGroup.vue' /* webpackChunkName: "components/ui-radio-group" */).then(c => wrapFunctional(c.default || c))
export const UiResult = () => import('../../components/ui/UiResult.vue' /* webpackChunkName: "components/ui-result" */).then(c => wrapFunctional(c.default || c))
export const UiRichVideo = () => import('../../components/ui/UiRichVideo.vue' /* webpackChunkName: "components/ui-rich-video" */).then(c => wrapFunctional(c.default || c))
export const UiSearchForm = () => import('../../components/ui/UiSearchForm.vue' /* webpackChunkName: "components/ui-search-form" */).then(c => wrapFunctional(c.default || c))
export const UiSearchFormPopularQueries = () => import('../../components/ui/UiSearchFormPopularQueries.vue' /* webpackChunkName: "components/ui-search-form-popular-queries" */).then(c => wrapFunctional(c.default || c))
export const UiSearchFormResults = () => import('../../components/ui/UiSearchFormResults.vue' /* webpackChunkName: "components/ui-search-form-results" */).then(c => wrapFunctional(c.default || c))
export const UiSection = () => import('../../components/ui/UiSection.vue' /* webpackChunkName: "components/ui-section" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/UiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiSwitch = () => import('../../components/ui/UiSwitch.vue' /* webpackChunkName: "components/ui-switch" */).then(c => wrapFunctional(c.default || c))
export const UiTag = () => import('../../components/ui/UiTag.vue' /* webpackChunkName: "components/ui-tag" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/UiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiTitle = () => import('../../components/ui/UiTitle.vue' /* webpackChunkName: "components/ui-title" */).then(c => wrapFunctional(c.default || c))
export const UiVideo = () => import('../../components/ui/UiVideo.vue' /* webpackChunkName: "components/ui-video" */).then(c => wrapFunctional(c.default || c))
export const UiVideoYoutube = () => import('../../components/ui/UiVideoYoutube.vue' /* webpackChunkName: "components/ui-video-youtube" */).then(c => wrapFunctional(c.default || c))
export const BlogSectionSimple = () => import('../../components/sections/BlogSection/BlogSectionSimple.vue' /* webpackChunkName: "components/blog-section-simple" */).then(c => wrapFunctional(c.default || c))
export const CardSectionApps = () => import('../../components/sections/CardSection/CardSectionApps.vue' /* webpackChunkName: "components/card-section-apps" */).then(c => wrapFunctional(c.default || c))
export const CardSectionAppsGrid = () => import('../../components/sections/CardSection/CardSectionAppsGrid.vue' /* webpackChunkName: "components/card-section-apps-grid" */).then(c => wrapFunctional(c.default || c))
export const CardSectionAppsItem = () => import('../../components/sections/CardSection/CardSectionAppsItem.vue' /* webpackChunkName: "components/card-section-apps-item" */).then(c => wrapFunctional(c.default || c))
export const CardSectionAppsSlider = () => import('../../components/sections/CardSection/CardSectionAppsSlider.vue' /* webpackChunkName: "components/card-section-apps-slider" */).then(c => wrapFunctional(c.default || c))
export const CardSectionAwards = () => import('../../components/sections/CardSection/CardSectionAwards.vue' /* webpackChunkName: "components/card-section-awards" */).then(c => wrapFunctional(c.default || c))
export const CardSectionFlags = () => import('../../components/sections/CardSection/CardSectionFlags.vue' /* webpackChunkName: "components/card-section-flags" */).then(c => wrapFunctional(c.default || c))
export const CardSectionGrid = () => import('../../components/sections/CardSection/CardSectionGrid.vue' /* webpackChunkName: "components/card-section-grid" */).then(c => wrapFunctional(c.default || c))
export const CardSectionShops = () => import('../../components/sections/CardSection/CardSectionShops.vue' /* webpackChunkName: "components/card-section-shops" */).then(c => wrapFunctional(c.default || c))
export const DocumentSectionSimple = () => import('../../components/sections/DocumentSection/DocumentSectionSimple.vue' /* webpackChunkName: "components/document-section-simple" */).then(c => wrapFunctional(c.default || c))
export const FaqSectionSimple = () => import('../../components/sections/FaqSection/FaqSectionSimple.vue' /* webpackChunkName: "components/faq-section-simple" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionArticles = () => import('../../components/sections/FeatureSection/FeatureSectionArticles.vue' /* webpackChunkName: "components/feature-section-articles" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionCarousel = () => import('../../components/sections/FeatureSection/FeatureSectionCarousel.vue' /* webpackChunkName: "components/feature-section-carousel" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionColumns = () => import('../../components/sections/FeatureSection/FeatureSectionColumns.vue' /* webpackChunkName: "components/feature-section-columns" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionInfoBlocks = () => import('../../components/sections/FeatureSection/FeatureSectionInfoBlocks.vue' /* webpackChunkName: "components/feature-section-info-blocks" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionRecommended = () => import('../../components/sections/FeatureSection/FeatureSectionRecommended.vue' /* webpackChunkName: "components/feature-section-recommended" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionSimple = () => import('../../components/sections/FeatureSection/FeatureSectionSimple.vue' /* webpackChunkName: "components/feature-section-simple" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionSimpleList = () => import('../../components/sections/FeatureSection/FeatureSectionSimpleList.vue' /* webpackChunkName: "components/feature-section-simple-list" */).then(c => wrapFunctional(c.default || c))
export const FeatureSectionTextImage = () => import('../../components/sections/FeatureSection/FeatureSectionTextImage.vue' /* webpackChunkName: "components/feature-section-text-image" */).then(c => wrapFunctional(c.default || c))
export const FormSectionConsultation = () => import('../../components/sections/FormSection/FormSectionConsultation.vue' /* webpackChunkName: "components/form-section-consultation" */).then(c => wrapFunctional(c.default || c))
export const FormSectionPartnership = () => import('../../components/sections/FormSection/FormSectionPartnership.vue' /* webpackChunkName: "components/form-section-partnership" */).then(c => wrapFunctional(c.default || c))
export const HeaderSectionBackground = () => import('../../components/sections/HeaderSection/HeaderSectionBackground.vue' /* webpackChunkName: "components/header-section-background" */).then(c => wrapFunctional(c.default || c))
export const LogoCloudSectionSimple = () => import('../../components/sections/LogoCloudSection/LogoCloudSectionSimple.vue' /* webpackChunkName: "components/logo-cloud-section-simple" */).then(c => wrapFunctional(c.default || c))
export const LogoCloudSectionWithFeatures = () => import('../../components/sections/LogoCloudSection/LogoCloudSectionWithFeatures.vue' /* webpackChunkName: "components/logo-cloud-section-with-features" */).then(c => wrapFunctional(c.default || c))
export const TeamSectionSimple = () => import('../../components/sections/TeamSection/TeamSectionSimple.vue' /* webpackChunkName: "components/team-section-simple" */).then(c => wrapFunctional(c.default || c))
export const TestimonialSectionSlider = () => import('../../components/sections/TestimonialSection/TestimonialSectionSlider.vue' /* webpackChunkName: "components/testimonial-section-slider" */).then(c => wrapFunctional(c.default || c))
export const VideoSectionSimple = () => import('../../components/sections/VideoSection/VideoSectionSimple.vue' /* webpackChunkName: "components/video-section-simple" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
