import { ref } from '@nuxtjs/composition-api';
import { Shop, Address } from '@webplatform/nuxt-cms-js-sdk';
import { useShops } from '@webplatform/frontkit/dist/composables';

interface ShopExtended extends Shop {
  addresses: Address[];
}

export default function useAsbisCompanies() {
  const companies = ref<any>([]);
  const { shops, fetchShops, meta } = useShops({
    page: 1,
    type_key: 'local-asbis',
    limit: 999,
  });

  const getCompany = (company: ShopExtended) => {
    const address = company.addresses[0];
    return {
      companyName: company.name,
      country: address?.country_name,
      city: address?.city_name,
      address: address?.address,
      image: { src: address?.country_flag },
      // Filter nullish values
      emails: address?.email?.filter((n) => n),
      phones: address?.phone?.filter((n) => n),
      links: [address?.url].filter((n) => n),
    };
  };

  const fetchCompanies = async () => {
    await fetchShops();
    companies.value = shops.value.map((shop: ShopExtended) => getCompany(shop));
  };

  return {
    companies,
    meta,
    fetchCompanies,
  };
}
