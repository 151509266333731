import { computed } from '@nuxtjs/composition-api';
import UiLink from '~/components/ui/UiLink.vue';


export default {
  __name: 'HeaderMainMenuMobileItem',
  props: {
  menuItem: {
    type: Object,
    default() {
      return {};
    },
  },
  isInnerCategory: {
    type: Boolean,
  },
},
  emits: ['open-category'],
  setup(__props, { emit }) {

const props = __props;





const hasChildren = computed(() => Boolean(props.menuItem.children?.length));

const openCategory = () => {
  emit('open-category');
};

return { __sfc: true,props, emit, hasChildren, openCategory, UiLink }
}

}