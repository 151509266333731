
import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { useSocials, useScrollHint, useMenus } from '~/composables';

import HeaderMainMenuMobileItem from '@/domains/menus/components/HeaderMainMenuMobileItem';

export default defineComponent({
  components: {
    HeaderMainMenuMobileItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menuMain: {
      type: Array,
      default() {
        return [];
      },
    },
    menuTop: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { socials } = useSocials();
    const { menuLocations: locations } = useMenus();
    const {
      initScrollHintIntersection,
      isShowScrollHint,
      resetScrollObserver,
    } = useScrollHint();

    const activeCategory = ref(null);
    const openCategory = (category) => {
      activeCategory.value = category;
      handleScrollHint();
    };

    const closeCategory = () => {
      activeCategory.value = null;
      handleScrollHint();
    };

    const openLocationList = () => {
      isLocationsOpen.value = true;
      handleScrollHint();
    };

    const closeLocationList = () => {
      isLocationsOpen.value = false;
      handleScrollHint();
    };

    const closeMenu = () => {
      closeCategory();
      emit('close-menu');
    };

    const isLocationsOpen = ref(false);

    watch(
      () => route.value.path,
      () => {
        closeMenu();
      },
    );

    watch(
      () => props.value,
      () => {
        if (props.value) handleScrollHint();
      },
    );

    const handleStopScroll = () => {
      handleScrollHint();
    };

    const handleScrollHint = () => {
      setTimeout(() => {
        const root = document.querySelector('.header-menu-mobile');

        if (root === null) {
          resetScrollObserver();
          return;
        }

        const target = root.querySelectorAll('.menu-item');

        if (target.length === 0) {
          resetScrollObserver();
        }

        if (target != null && target.length !== 0) {
          initScrollHintIntersection(root, target[target.length - 1]);
        }
      }, 1000);
    };

    return {
      socials,
      activeCategory,
      openCategory,
      closeCategory,
      closeMenu,
      isLocationsOpen,
      locations,
      isShowScrollHint,
      handleScrollHint,
      openLocationList,
      closeLocationList,
      resetScrollObserver,
      handleStopScroll,
    };
  },
});
