import { provide, ref, watch, useRoute } from '@nuxtjs/composition-api';
import { vOnClickOutside } from '@vueuse/components';
import { useWindowScroll } from '@vueuse/core';


export default {
  __name: 'UiPopover',
  props: {
  closeOnScroll: {
    type: Boolean,
    default: false,
  },
  trigger: {
    type: String,
    default: 'click',
    validator(value) {
      return ['click', 'hover'].includes(value);
    },
  },
},
  setup(__props) {

const props = __props;



const route = useRoute();
const isOpen = ref(false);
const popoverElement = ref();

const open = () => {
  if (isOpen.value === true) return;
  isOpen.value = true;
};

const close = () => {
  if (isOpen.value === false) return;
  isOpen.value = false;
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

const { y } = useWindowScroll();

watch(y, () => {
  if (!props.closeOnScroll) return;
  close();
});

watch(route, () => {
  close();
});

provide('isOpen', isOpen);
provide('toggle', toggle);
provide('open', open);
provide('close', close);
provide('trigger', props.trigger);
provide('popoverElement', popoverElement);

return { __sfc: true,props, route, isOpen, popoverElement, open, close, toggle, y, vOnClickOutside }
}

}