
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    to: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    download: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup(props) {
    const isExternalLink = computed(() =>
      /^(https?|tel|mailto):/.test(props.to ?? ''),
    );
    const isSamePageLink = computed(() => props.to?.startsWith('#'));

    const isNativeLink = computed(
      () => isExternalLink.value || isSamePageLink.value,
    );

    const linkTarget = computed(() =>
      props.target ?? isExternalLink.value ? '_blank' : '_self',
    );

    return {
      isExternalLink,
      isNativeLink,
      linkTarget,
    };
  },
});
