
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    minWidth: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    fitHeight: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const variants: Record<string, any> = {
      primary: {
        base: 'bg-blue-800 border-blue-800 text-white hover:bg-blue-700 hover:border-blue-700 active:bg-border-blue-800 active:border-border-blue-800',
        inverted: 'bg-white border-white text-blue-800 hover:text-blue-800',
      },
      secondary: {
        base: 'border-blue-800 text-blue-800 hover:border-blue-700 hover:text-blue-700',
        inverted:
          'border-white text-white hover:border-gray-100 hover:text-gray-100',
      },
    };

    const sizes: Record<string, string> = {
      xs: 'h-8 px-4 text-xs',
      sm: 'h-10 px-5 text-sm',
      md: 'h-14 px-8 text-md',
      xl: 'h-16 px-8 text-md',
    };

    const currentVariant = computed(
      () =>
        `${variants[props.variant][props.inverted ? 'inverted' : 'base']} ${
          sizes[props.size]
        }`,
    );

    const clickEvent = () => {
      emit('click');
    };

    return {
      currentVariant,
      clickEvent,
    };
  },
});
