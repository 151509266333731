import { render, staticRenderFns } from "./HeaderTopMenu.vue?vue&type=template&id=34b82642"
import script from "./HeaderTopMenu.vue?vue&type=script&lang=ts"
export * from "./HeaderTopMenu.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSwitcher: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod@6/components/LanguageSwitcher.vue').default})
