
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useMenus } from '~/composables';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
  },
  setup() {
    const { route } = useContext();
    const { menuTop, menuMain, menuFooter, menuBottom } = useMenus();

    const isHomepage = computed(() => route.value?.fullPath === '/');

    return {
      isHomepage,
      menuTop,
      menuMain,
      menuFooter,
      menuBottom,
    };
  },
  // @ts-ignore
  head() {
    const { baseUrl } = this.$config;
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const { googleGtagId } = this.$config;
    const scripts = [];
    const store = this.$store;
    const favicon = store.getters['config/getFavicon'];

    if (googleGtagId)
      scripts.push({
        src: `https://www.googletagmanager.com/gtag/js?id=${googleGtagId}`,
        async: true,
      });

    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: `theme-ab`,
      },
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${baseUrl}/images/og-image.jpg`,
        },
        { hid: 'og:image:width', property: 'og:image:width', content: '240' },
        { hid: 'og:image:height', property: 'og:image:height', content: '240' },
        ...i18nHead.meta,
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: favicon ?? '/favicon.ico' },
        ...i18nHead.link,
      ],
      script: scripts,
    };
  },
});
