
import { defineComponent } from '@nuxtjs/composition-api';
import { useMenus } from '~/composables';

export default defineComponent({
  setup() {
    const { menuLocations: locations } = useMenus();

    return {
      locations,
    };
  },
});
