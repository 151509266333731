
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},

  props: {
    value: {
      type: String,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const searchText = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
      },
    });

    const search = () => {
      emit('search', searchText.value);
    };

    return {
      searchText,
      search,
    };
  },
});
