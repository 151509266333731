
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
});
