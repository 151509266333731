var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'ui-link' : 'button',{tag:"component",staticClass:"relative inline-flex",class:[
    _vm.block ? 'w-full' : 'w-auto',
    _vm.disabled ? 'cursor-not-allowed opacity-40' : '',
  ],attrs:{"type":_vm.type,"to":_vm.to,"disabled":_vm.disabled,"target":_vm.target},on:{"click":_vm.clickEvent}},[_c('span',{staticClass:"font-sans relative flex items-center justify-center rounded-full border-[1px] leading-none transition",class:[
      { 'w-full': _vm.block },
      { 'max-h-full': _vm.fitHeight },
      _vm.currentVariant,
    ],style:({ minWidth: _vm.minWidth })},[_vm._t("default"),_vm._v(" "),(_vm.icon)?_c('UiIcon',{staticClass:"ml-2.5 h-5 w-5",attrs:{"icon":_vm.icon}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }