
import { defineComponent, ref, useRouter } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const router = useRouter();

    const searchText = ref('');
    const results = ref([
      'Multiboard Prestigio 98" Prime Series (UHD)',
      'Multiboard Prestigio 43" D-Series (UHD)',
      'Multiboard Prestigio 55" L-Series (UHD)',
      'Multiboard Prestigio 55" Premium Line (UHD)',
      'Multiboard Prestigio 66" L-Series (UHD)  ',
      'Multiboard Prestigio 70" L-Series (UHD)  ',
      'Multiboard Prestigio 75" L-Series (UHD)  ',
      'Multiboard Prestigio 95" L-Series (UHD)  ',
    ]);

    const popular = ref([
      'Multiboard Prestigio 98" Prime Series (UHD)',
      'Prestigio DS Totem 55”',
      'Collaboration Bar Alpha',
      'Service centers',
      'Warranty terms',
    ]);

    const searchItem = (item: string) => {
      searchText.value = item;
    };
    const search = () => {
      if (!searchText.value) return;
      router.push({
        path: '/search/products',
        query: { search: searchText.value },
      });
      emit('search');
    };

    return {
      searchText,
      results,
      popular,
      searchItem,
      search,
    };
  },
});
